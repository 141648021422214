
export const isValidEmail = (email) => {
  const regExp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
}

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

export const showCurrencyPrice = (finalPrice, inCurrency) => {
  let euro, usd
  if(document.getElementById("USD")){
    usd = document.getElementById("USD").value
    euro = document.getElementById("EURO").value
  }

  let price
  switch (inCurrency) {
    case "EURO":
      price = (finalPrice / euro).toFixed(2)
      break;
    case "USD":
      price = (finalPrice / usd).toFixed(2)
      break;

    default:
      price = finalPrice
      break;
  }
  return price
}