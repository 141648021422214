import {
    MENU_DATA_START,
    MENU_DATA_SUCCESS,
    CURRENCY_VALUE,
    SEARCH_CATEGORY,
    GLOBAL_SEARCH_TEXT,
    CHANGE_LANGUAGE,
    SET_GLOBAL_SEARCH_RESULT,
    HIDE_GLOBAL_SEARCH_CONTAINER,
    MENU_DATA_STATIC_PAGES
} from "./Actions";

import i18n from "../i18n";
import {storePrefLang} from "../services/Prefs";
import moment from "moment";

export function menuData(payload) {
    return dispatch => {
        dispatch({ type: MENU_DATA_START });
        return setGlobalMenu(dispatch, payload);
    }
}

const setGlobalMenu = (dispatch, payload) => {

    const menuData = { menu: payload }
    dispatch({
        type: MENU_DATA_SUCCESS,
        payload: menuData
    })
};

export function menuDataStaticPages(payload) {
    return dispatch => {
        return setGlobalStaticPages(dispatch, payload);
    }
}

const setGlobalStaticPages = (dispatch, payload) => {

    const staticPages = { menu: payload }
    dispatch({
        type: MENU_DATA_STATIC_PAGES,
        payload: staticPages
    })
};



export function hideLiveSearchContainer() {
    return dispatch => {
        dispatch({
            type: HIDE_GLOBAL_SEARCH_CONTAINER,
        })
    }
}

export function setGlobalLiveSearchResult(payload) {
    return dispatch => {
        return globalSearchResult(dispatch, payload);
    }
}

const globalSearchResult = (dispatch, payload) => {
    dispatch({
        type: SET_GLOBAL_SEARCH_RESULT,
        payload
    })
}
export function setGlobalCurrency(payload) {
    return dispatch => {
        const currency = { currency: payload }
        dispatch({ type: CURRENCY_VALUE, payload: currency });
    }
}

export function setGlobalSearchCategory(payload) {
    
    return dispatch => {
        const searchCategory = { searchCategory: payload }
        dispatch({ type: SEARCH_CATEGORY, payload: searchCategory });
    }
}

export function setGlobalSearchText(payload) {
    
    return dispatch => {
        dispatch({ type: GLOBAL_SEARCH_TEXT, payload });
    }
}

export function changeLanguage(lng) {
    i18n.changeLanguage(lng);
    moment.locale(lng);
    storePrefLang(lng);
    return {type: CHANGE_LANGUAGE, payload: lng};
}