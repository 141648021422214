import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Header from "../../header"
import Footer from "../../footer"
import Categories from "../widgets/categories"

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withNamespaces } from "react-i18next";
import { GET_STATIC_PAGE_INFO } from "../../../helpers/constants"
import axiosFetch from "../../../helpers/axios"
import Spinner from "../../../helpers/spinner"


import "./styles.scss";

function StaticPage(props) {
  const {
    lng,
    // t,
  } = props;

  const epmtyPageInfo = {
    descriptionRo: "",
    descriptionRu: "",
    titleRo: "",
    titleRu: "",
    imageUrl: "",
    blocks: []
  }

  const { pageId } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [pageInfo, setPageInfo] = useState(epmtyPageInfo)

  useEffect(() => {

    setIsLoading(true)
    const formData = new FormData()
    formData.append("pageId", pageId)

    const pageInfo = axiosFetch(GET_STATIC_PAGE_INFO, formData)
    promiseResolved(pageInfo)


  }, [pageId])

  const { descriptionRo, descriptionRu, titleRo, titleRu, imageUrl, blocks } = pageInfo

  const title = lng === "ru" ? titleRu : titleRo
  const description = lng === "ru" ? descriptionRu : descriptionRo

  const promiseResolved = (pageInfo) => {
    Promise.resolve(pageInfo).then((info) => {
      setPageInfo(info)
      setIsLoading(false)
    })
  }

  const renderPageBlocks = () => {
    const content = blocks.map((block, index) => {
      const { descriptionRo, descriptionRu, titleRo, titleRu, imageUrl, imgAlign } = block
      const blockTitle = lng === "ru" ? titleRu : titleRo
      const blockTDescription = lng === "ru" ? descriptionRu : descriptionRo
      return (
        <div className="col-md-12 ">
          <div className="contact-title">
            <h3>{blockTitle}</h3>
          </div>
          <div className="clearfix address">
            <span className="contact-span">
              <img src={imageUrl} className={`single-page-img ${imgAlign === "right" && 'fl-right'}`} alt={blockTitle} />
              <div
                className="block-description"
                dangerouslySetInnerHTML={{
                  __html: blockTDescription,
                }}
              />
            </span>
          </div>
        </div>
      )
    })

    return content;
  }

  const getContent = () => {
    return (
      <div className="col-md-12 ">

        <div className="contact-title">
          <h3>{title}</h3>
        </div>
        <div className="clearfix address">
          <span className="contact-span">
            <img src={imageUrl} className="single-page-img" alt={title} />
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </span>
        </div>
        <div className="page-blocks-container">
          {renderPageBlocks()}
        </div>

      </div>
    )
  }

  return (
    <div className="cnt-home static-page-info">
      <Header />
      <div className="body-content contacts-page outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder">
              <div className="row">
                <div>
                  {isLoading ? <Spinner /> : getContent()}

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(StaticPage));