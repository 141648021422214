import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Header from "../../header"
import Footer from "../../footer"
import Categories from "../widgets/categories"
//import Filters from "../widgets/filters"
// import { Link } from "react-router-dom"
import { GET_CATALOGS_LIST } from "../../../helpers/constants"
import axiosFetch from "../../../helpers/axios"
import Spinner from "../../../helpers/spinner"
import PageTitle from "../../../helpers/page-title"
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import "./styles.scss"

function Catalogs(props) {
  const { lng, t } = props
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [catalogsList, setCatalogsList] = useState()




  useEffect(() => {

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);

    const getCatalogsList = () => {
      setIsLoading(true)
      const list = axiosFetch(GET_CATALOGS_LIST)
      promiseResolved(list)
    }


    getCatalogsList()

  }, [])



  const promiseResolved = (productsResult, state) => {
    Promise.resolve(productsResult).then((arrList) => {

      setCatalogsList(arrList)
      setIsLoading(false)
    })
  }

  const renderFilterTopBar = () => {
    return (
      <div className="clearfix">
        <div className="row">
          <div className="col col-sm-6 col-md-12">
            <PageTitle text={t("Catalogs")} />
          </div>
        </div>
      </div>
    )
  }

  const renderProductsItems = (catalogsList) => {
    console.log("catalogsList", catalogsList)
    const content = catalogsList.map(({ name, nameRo, url, catalogImage }, index) => {

      const finalName = lng === "ru" ? name : nameRo
      return <a href={url} traget="_blank" className="catalog-container col-md-4">
        <div className="catalog-image" style={{ backgroundImage: `url(${catalogImage})`}}></div>
        <h4>{finalName}</h4>
      </a>
    })
    return content
  }

  const renderEmptyContainer = () => {
    return (
      <div className="col-sm-6 col-md-12 wow fadeInUp animated no-content" >
        {t('Empty_category')}
      </div>)
  }

  const content = isLoading ? <Spinner /> : catalogsList && catalogsList.length > 0 ? renderProductsItems(catalogsList) : renderEmptyContainer()

  let finalName = "T-par | Print Agency"



  return (
    <div className="cnt-home">
      <MetaTags>
        <title>{`${finalName} | T-par | Print Agency`}</title>
        <meta name="og:description" content={`${finalName} | T-par | Print Agency`} />
        <meta property="og:title" content={finalName} />
      </MetaTags>
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories currentCategory={id} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder">
              {renderFilterTopBar()}
              <div className="tab-pane active " id="grid-container">
                <div className="category-product">
                  <div className="row catalog-main-content">
                    {content}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(Catalogs));
