import React, { useState } from "react";
import Spinner from "../../../helpers/spinner"
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Cart from "./cart"
import classNames from 'classnames'
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss"

function HeaderNavBar(props) {

  const { menuData: { menu = [], staticPages = [] }, lng, t } = props

  const isMobile = window.innerWidth <= 500
  const contentMenu = isMobile ? menu : staticPages
  const [isShow, setIsShow] = useState(true)


  const renderStatisticPagesMenuItems = () => {
    const menuContent = contentMenu.map((menuItem, index) => {
      const { id } = menuItem

      const finalName = isMobile ? lng === "ru" ? menuItem.nameRu : menuItem.nameRo : lng === "ru" ? menuItem.titleRu : menuItem.titleRo

      return (
        <li className="mega-menu" key={index}>

          <Link to={`/category/${id}`} onClick={() => setIsShow(!isShow)}>

            <b>{finalName}</b>

          </Link>
        </li>
      )
    })

    return menuContent
  }
  const renderSpinner = () => {
    return (
      <div className="spinner-parent">
        <Spinner />
      </div>
    )
  }
  console.log("contentMenu", contentMenu)
  const allMenu = () => {
    return (
      <div //className="nav-bg-class"
        className={classNames({
          'nav-bg-class': true,
          'show-categories': isShow
        })}
      >
        <div
          className="navbar-collapse"
          id="mc-horizontal-menu-collapse"
        >
          <div className="nav-outer">
            <ul className="nav navbar-nav">
              {contentMenu.length && renderStatisticPagesMenuItems()}
              <li className="mega-menu" >
                <Link to={`/catalogs`} >
                  <b>{t('Catalogs')}</b>
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    )
  }

  const content = menu ? allMenu() : renderSpinner()

  return (
    <div className="header-nav animate-dropdown">
      <div className="container">
        <div className="yamm navbar navbar-default" role="navigation">
          <div className="navbar-header">
            <button
              data-target="#mc-horizontal-menu-collapse"
              //data-toggle="collapse"
              className="navbar-toggle"
              onClick={() => setIsShow(!isShow)}
              type="button"
            >
              <span className="sr-only"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <div className="cart-container-for-mobile">
              <Cart />
            </div>
          </div>
          {content}

        </div>
      </div>
    </div>
  );

}
//export default HeaderNavBar;

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(HeaderNavBar));