import React from "react";
import Cart from "./cart";
import SearchField from "./search-field";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { setGlobalSearchCategory } from "../../../actions/MenuDataActionCreators";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss";
import logo from "./logo.png";

function HeaderMiddleBar(props) {
  const {
    adminPhone,
    t,
    notification,
    titleRu,
    titleRo,
    descriptionRu,
    descriptionRo,
    lng,
  } = props;
  const finalTitle = lng === "ru" ? titleRu : titleRo;
  const finalDescription = lng === "ru" ? descriptionRu : descriptionRo;
  return (
    <div className="main-header">
      <div className="container">
        <div className="row middle-header">
          <div className="col-xs-12 col-sm-12 col-md-3 logo-holder">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
              <div className="phone-mob">
                <a href={`tel:${adminPhone}`}>{adminPhone}</a>
              </div>
            </div>
          </div>
          <SearchField {...{ t }} />
          <span className="cart-for-desktop">
            <Cart {...{ t }} />
          </span>
        </div>
      </div>
      {notification > 0 && (
        <div className="global-notification">
          <h3>{finalTitle}</h3>
          <div
            className="notification-description"
            dangerouslySetInnerHTML={{
              __html: finalDescription,
            }}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  searchCategory: state.MenuDataReducer.searchCategory,
  adminPhone: state.GlobalSettingsReducer.adminPhone,
  notification: state.GlobalSettingsReducer.notification,
  titleRu: state.GlobalSettingsReducer.titleRu,
  titleRo: state.GlobalSettingsReducer.titleRo,
  descriptionRu: state.GlobalSettingsReducer.descriptionRu,
  descriptionRo: state.GlobalSettingsReducer.descriptionRo,
  lng: state.PrefsReducer.lng,
});

export default withRouter(
  compose(
    connect(mapStateToProps, {
      setGlobalSearchCategory,
    }),
    withNamespaces()
  )(HeaderMiddleBar)
);
