import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss";

function Categories(props) {
  const {
    menuData: { menu },
    currentCategory,
    lng
  } = props;

  const renderSubcategories = renderSubcategories => {
    const content = renderSubcategories.map((menuItem, index) => {
      const { subCategoryName, subCategoryUrl, subCategoryNameRo } = menuItem;
      const finalSubCatName = lng === "ru" ? subCategoryName : subCategoryNameRo
      return (
        <li>
          <Link to={subCategoryUrl}>
            {finalSubCatName}
          </Link>
        </li>
      )
    })

    return content
  }

  const renderCategories = () => {

    const content = menu.map((menuItem, index) => {
      const { name, url, id, categoryImage, nameRo, subCategories, label, labelColor, labelRo } = menuItem;

      const activeClass = currentCategory === id ? "active-category" : ""
      const finalTitle = lng === "ru" ? name : nameRo
      const finalLabel = lng === "ru" ? label : labelRo

      return (


        <li className="dropdown menu-item">
          {subCategories.length === 0 ?

            <Link to={url}>
              <img src={categoryImage} className={`category-image ${activeClass}`} alt={finalTitle} />
              {finalTitle}

            </Link>

            :
            // <a href="/" className="dropdown-toggle" data-toggle="dropdown">
            <Link to={url}>
              <img src={categoryImage} className={`category-image ${activeClass}`} alt={finalTitle} />
              {finalTitle}
            </Link>
            // </a>
          }

          {finalLabel &&
            <span className="cat-label" style={{ backgroundColor: labelColor }}>
              {finalLabel}
            </span>
          }


          {subCategories.length > 0 && <ul className="dropdown-menu mega-menu">
            <li className="yamm-content">
              <div className="row">
                <div className="col-xs-12 col-sm-12 ">
                  <ul>

                    {renderSubcategories(subCategories)}
                  </ul>
                </div>
                <div className="dropdown-banner-holder">
                  <a href="/"><img alt="" src="assets/images/banners/banner-side.png" /></a>
                </div>
              </div>
            </li>
          </ul>}
        </li>


        // <li className={`dropdown menu-item ${activeClass}`} key={index} >
        //    <Link to={url}>
        //      <img src={categoryImage} className={`category-image ${activeClass}`} alt={finalTitle}/>
        //     {finalTitle}
        //   </Link>
        // </li>
      );
    });

    return content;
  };

  return (
    <div className="side-menu ">
      <nav className="yamm " role="navigation">
        <ul className="nav">{menu && renderCategories()}</ul>
      </nav>
    </div>
  );
}

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
});

export default withRouter(connect(mapStateToProps, {})(Categories));
