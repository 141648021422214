import React, { useState } from "react";
import Button from "../../../../helpers/button";

import "./styles.scss";
//setMinValue(e.target.value)
function Filters(props) {

  const { setRange } = props;
  const [setMin, setMinValue] = useState(0);
  const [setMax, setMaxValue] = useState(100000);

  const setMinPrice = (minValue) => {
      setMinValue(minValue);
  };
  const setMaxPrice = (maxValue) => {
      setMaxValue(maxValue);
   
  };

  return (
    <div className="sidebar">
      <div className="sidebar-module-container">
        <div className="sidebar-filter">
          <div className="sidebar-widget wow fadeInUp">
            <div className="widget-header">
              <h4 className="widget-title">Цена товара</h4>
            </div>
            <div className="sidebar-widget-body m-t-10">
              <div className="price-range-holder">
                <span className="min-max">
                  <label htmlFor="volume">
                    <span className="pull-left">От ({setMin})</span>
                  </label>
                  <input
                    type="range"
                    id="volume"
                    name="volume"
                    min="0"
                    max="2000"
                    value={setMin}
                    onChange={(e) => setMinPrice(e.target.value)}
                  />
                </span>

                <div>
                  <span className="min-max">
                    <label htmlFor="cowbell">
                      <span className="pull-left">До ({setMax})</span>
                    </label>
                    <input
                      type="range"
                      id="cowbell"
                      name="cowbell"
                      min="0"
                      max="2000"
                      value={setMax}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    />
                  </span>
                </div>
              </div>

              <div></div>
              <Button
                className="lnk btn btn-primary"
                text={"Показать"}
                onClick={() => setRange(setMin, setMax)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Filters;
//style="border:0; color:#666666; font-weight:bold;text-align:center;"
